// =============================
// Imports
// =============================

// External Dependencies
import Router from 'next/router';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// =============================
// Component
// =============================

class PublicRoute extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    const { isLogged } = this.props;

    if (isLogged) {
      Router.push('/dashboard');
    }
  }

  componentDidUpdate(prevProps) {
    const { isLogged } = this.props;

    if (!prevProps.isLogged && isLogged) {
      Router.push('/dashboard');
    }
  }

  render() {
    const { children, isLogged } = this.props;

    if (isLogged) return null;
    return children;
  }
}

function mapStateToProps({ user }) {
  return {
    isLogged: user.isLogged,
  };
}

export default connect(mapStateToProps)(PublicRoute);
