// =============================
// Imports
// =============================

import { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';
import locales from '../../../config/locales';

import { setLocalPreferences as setLocalPreferencesBase } from '../../../store/actions/CoreActions';

import { Lang, Languages } from './styles';

// =============================
// Component
// =============================

class NoAuthLanguageSwitcher extends Component {
  static propTypes = {
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    setLocalPreferences: PropTypes.func,
  };

  static defaultProps = {
    setLocalPreferences: () => {},
  };

  setUserLanguage = (language) => {
    const { setLocalPreferences } = this.props;

    setLocalPreferences({ language });
  };

  render() {
    const { i18n: { language } } = this.props;
    const { languagesList } = locales;

    return (
      <Languages>
        {languagesList.map((languageOption, i) => (
          <Fragment key={languageOption}>
            {i > 0 && '/'}
            <Lang
              selected={languageOption === language}
              onClick={() => this.setUserLanguage(languageOption)}
            >
              {languageOption}
            </Lang>
          </Fragment>
        ))}
      </Languages>
    );
  }
}

export default compose(
  connect(() => ({}), {
    setLocalPreferences: setLocalPreferencesBase,
  }),
  withTranslation(),
)(NoAuthLanguageSwitcher);
