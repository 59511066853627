// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';

import { StyledButton } from '../global.styles';
import FinalLink from '../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  min-width: 102.4rem;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 56rem;
  width: 40%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 36rem;
`;

export const Background = styled.div`
  background-image: url(/static/auth-background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90% auto;
  height: 100%;
  width: 100%;
`;

export const Logo = styled(Icons.LgApp).attrs({
  width: '19.5rem',
})`
  margin: 0 auto 5rem;
`;

export const FormSubmit = styled(StyledButton)`
  margin-bottom: 2rem;
`;

export const Label = styled.div`
  margin-bottom: 3.6rem;
`;

export const BackToHome = styled(FinalLink)`
  font-size: 1.3rem;
`;

export const Notification = styled.div`
  margin-bottom: 3.6rem;
  width: 100%;

  ${({ center }) => center
    && css`
      text-align: center;
    `};

  ${({ theme, type }) => (type === 'error'
    ? css`
          color: ${theme.colors.type.error};
        `
    : css`
          color: ${theme.colors.type.success};
        `)};
`;
